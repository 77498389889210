<section>
    <app-hero></app-hero>
</section>


<section class="sales">
    <div class="content-padding center">
        <h2 class="mat-display-2">Legyen az Ön cége is elégedett partnerünk</h2>
        <div class="sales-intro center">
            <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Mollitia doloribus et dolorem nemo possimus sit adipisci reprehenderit deserunt, fugit debitis sequi ut magni quos beatae sed libero accusantium earum sapiente? Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quia expedita et vero voluptates fuga quas tenetur eum sit hic aut quod neque molestias nihil tempora ratione dolorem magni, cum debitis?</p>
        </div>
        <div class="usp-wrapper">
            <mat-card class="mat-elevation-z4">
                <mat-card-content>
                    <div fxLayout="column" fxLayout.gt-md="row">
                        <div class="usp-question" fxFlex.gt-md="30">
                            <h2>Lorem ipsum dolor sit amet?</h2>
                        </div>
                        <div class="usp-answer">
                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Error odit odio consequatur quos pariatur, iure corrupti alias molestias porro blanditiis vel? Ut voluptas hic beatae illum, fugiat odit. Aliquid consequuntur culpa voluptatum debitis itaque ut aperiam reiciendis! Odio nobis soluta ab! Repellat aliquid deserunt optio, ut magni sint consequuntur recusandae!
                        </div>
                    </div>
    
                </mat-card-content>
            </mat-card>
            <mat-card class="mat-elevation-z4">
                <mat-card-content>
                    <div fxLayout="column" fxLayout.gt-md="row">
                        <div class="usp-question" fxFlex.gt-md="30">
                            <h2>Lorem ipsum dolor sit amet?</h2>
                        </div>
                        <div class="usp-answer">
                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Error odit odio consequatur quos pariatur, iure corrupti alias molestias porro blanditiis vel? Ut voluptas hic beatae illum, fugiat odit. Aliquid consequuntur culpa voluptatum debitis itaque ut aperiam reiciendis! Odio nobis soluta ab! Repellat aliquid deserunt optio, ut magni sint consequuntur recusandae!
                        </div>
                    </div>
    
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</section>

<section id="contact-us">
    <div class="content-padding center">
        <h2 class="mat-display-2">Lépjen velünk kapcsolatba!</h2>
        <div fxLayoutAlign="center">
            <app-contact-form fxFlex.gt-sm="40"></app-contact-form>
        </div>
    </div>
</section>