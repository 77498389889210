<section class="top-divider"></section>
<div fxLayout="column" fxLayoutAlign="start center">
    <div class="single-wrapper content-padding" *ngIf="job" @enterAnim>
    
        <section class="image center mat-elevation-z20" *ngIf="category != 'video';else youtube">
            <!-- <app-image-loader [src]="'/assets/refs/' + job.img" [title]="job.title"></app-image-loader> -->
            <img fx [lazyLoad]="'/assets/refs/' + job.img" [alt]="job.title">
        </section>

        <ng-template #youtube>
            <div class="yt-container mat-elevation-z20">
                <iframe class="responsive-iframe" [src]="('https://www.youtube.com/embed/' + job.img) | safeUrl" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
        </ng-template>
        
        <section class="details content-padding">
            <h1>{{ job.title }}</h1>
            <p>{{ job.description }}</p>
        </section>
    
        <section class="gallery">
            <div *ngFor="let item of job.gallery">
                <img [lazyLoad]="'/assets/refs/' + item" class="mat-elevation-z4">
            </div>
        </section>
    
    </div>
</div>
