import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HttpClientModule } from '@angular/common/http'

// Firebase
import { AngularFireModule } from '@angular/fire';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from 'src/environments/environment';
import { SharedModule } from './shared/shared.module';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { HomeComponent } from './pages/home/home.component';
import { CdkScrollableModule } from '@angular/cdk/scrolling';
import { JobdetailsComponent } from './components/jobdetails/jobdetails.component';
// import { JobsComponent } from './components/jobs/jobs.component';
import { FilterJobsPipe } from './pipes/filter-jobs.pipe';
import { CategoryComponent } from './pages/category/category.component';
import { CategorySectionComponent } from './components/category-section/category-section.component';
import { JobCardComponent } from './components/job-card/job-card.component';
import { SingleComponent } from './pages/single/single.component';
import { ChangingTextComponent } from './components/changing-text/changing-text.component';
import { ImageLoaderComponent } from './components/image-loader/image-loader.component';
import { HeroComponent } from './components/hero/hero.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { SupportCardComponent } from './components/support-card/support-card.component';
import { ContactFormComponent } from './contact-form/contact-form.component';

@NgModule({
  declarations: [
    AppComponent,
    ToolbarComponent,
    HomeComponent,
    JobdetailsComponent,
    // JobsComponent,
    FilterJobsPipe,
    CategoryComponent,
    CategorySectionComponent,
    JobCardComponent,
    SingleComponent,
    ChangingTextComponent,
    ImageLoaderComponent,
    HeroComponent,
    SafeUrlPipe,
    SupportCardComponent,
    ContactFormComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    AngularFireModule.initializeApp(environment.firebase),
    CdkScrollableModule,
    FlexLayoutModule,
    HttpClientModule,
    LazyLoadImageModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
