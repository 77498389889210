// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyBTtz_sfq1Sd0vlhvJqs5V8eDepe-EbWd8",
    authDomain: "artworxx-references.firebaseapp.com",
    databaseURL: "https://artworxx-references.firebaseio.com",
    projectId: "artworxx-references",
    storageBucket: "artworxx-references.appspot.com",
    messagingSenderId: "601911106361",
    appId: "1:601911106361:web:97fa391cd0f948abb7e6e8",
    measurementId: "G-NRGJSJ1P64"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
