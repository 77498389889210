<form #contactForm="ngForm" (ngSubmit)="onSubmit()" name="contactForm" id="contactForm">

    <mat-form-field appearance="outline">
        <mat-label i18n>Név</mat-label>
        <input matInput name="name" #name="ngModel" [(ngModel)]="model.name" ngModel required>
        <!-- <mat-hint>Hint</mat-hint> -->
        <mat-error i18n *ngIf="name.errors?.required">Kérjük, adja meg a nevét!</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label i18n>Email cím</mat-label>
        <input 
        type="email" 
        matInput name="email" 
        #email="ngModel" 
        pattern="^((([!#$%&'*+\-/=?^_`{|}~\w])|([!#$%&'*+\-/=?^_`{|}~\w][!#$%&'*+\-/=?^_`{|}~\.\w]{0,}[!#$%&'*+\-/=?^_`{|}~\w]))[@]\w+([-.]\w+)*\.\w+([-.]\w+)*)$"
        [(ngModel)]="model.email" 
        ngModel 
        required>
        <!-- <mat-hint>Hint</mat-hint> --> 
        <!-- <mat-error i18n></mat-error> -->
        <mat-error i18n *ngIf="email.errors?.required">Kérjük, adja meg az email címét!</mat-error>
        <mat-error i18n *ngIf="email.errors?.pattern">Kérjük, ügyeljen a helyes email formátum megadására!</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label i18n>Üzenet</mat-label>
        <textarea 
        cdkTextareaAutosize 
        matInput name="message" 
        #message="ngModel" 
        [minlength]="messageMinlength"
        [(ngModel)]="model.message" 
        ngModel 
        required></textarea>
        <!-- <mat-hint>Hint</mat-hint> --> 
        <mat-error i18n *ngIf="message.errors?.required">Kérjük, írjon nekünk pár sort!</mat-error>
        <mat-error i18n *ngIf="message.errors?.minlength">Legalább {{ messageMinlength }} karakter szükséges.</mat-error>
    </mat-form-field>

    <button mat-stroked-button type="submit" [disabled]="contactForm.invalid" i18n>Küldés</button>
    
</form>
