<div class="hero dark" lazyLoad="../../../assets/images/gilles-lambert-pb_lF8VWaPU-unsplash.jpg">
    <div class="content padding">
            
        <h1 i18n="user welcome title" ngStyle.gt-md="font-size: 3rem;">
            Kézzel fogható tartalom <br ngStyle.lt-sm="display:none;">egy 
            <app-changing-text 
                [texts]="['digitális', 'rohanó', 'gyorsuló', 'őrült']"
            ></app-changing-text>
            világban
        </h1>
    
        <p i18n="user welcome text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Placeat voluptatum eius necessitatibus nulla consequuntur eum iure voluptate est error esse! Quas odit tempore consequatur mollitia saepe aliquam, hic aliquid voluptatem.</p>
    
        <!-- <div class="arrow">
            <mat-icon>arrow_downward</mat-icon>
        </div> -->
        
    </div>
</div>

<div class="logos-wrapper">
    <div class="logos">
        <div class="logo" *ngFor="let logo of logos" [ngStyle]="{ 'height.px': logo.height }">
            <img src="../../../assets/logo/{{ logo.filename }}" height="{{ logo.height }}" alt="">
        </div>
    </div>
</div>