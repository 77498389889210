import { Jobdescription } from 'src/app/interfaces/jobdescription';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-job-card',
  templateUrl: './job-card.component.html',
  styleUrls: ['./job-card.component.scss']
})
export class JobCardComponent implements OnInit {

  @Input() job: Jobdescription;
  
  constructor() {
  }

  ngOnInit(): void {
  }

}
