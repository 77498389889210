import { JobService } from 'src/app/services/job.service';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Jobdetail } from 'src/app/interfaces/jobdetail';

@Component({
  selector: 'app-jobdetails',
  templateUrl: './jobdetails.component.html',
  styleUrls: ['./jobdetails.component.scss']
})
export class JobdetailsComponent implements OnInit {

  @Output() openSidebar = new EventEmitter();
  
  detail:Jobdetail;
  loading:boolean = false;
  
  constructor(private jobService:JobService) { 
    jobService.id.subscribe(next => {
      console.log('job description load for id: ' + next);
      // clear detail values
      this.detail = null;
      // make spinner visible
      this.loading = true;
      // open sidebar
      this.openSidebar.emit();
      // load jobdetail
      jobService.getJobdetail(next)
      .then((jobdetail) => {
        this.detail = jobdetail;
        // hide spinner
        this.loading = false;
      })
      .catch(err => { console.log(err); })
    })
  }

  ngOnInit(): void {
  }

  isLoading(){
    return this.loading;
  }

}
