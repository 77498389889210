<div class="wrapper">
    <img class="main-img" *ngIf="detail && detail.img" [src]="detail?.img" [alt]="detail?.title">
    <h1>{{ detail?.title }}</h1>
    <p>{{ detail?.description }}</p>

    <div *ngIf="detail?.description">
        <mat-divider></mat-divider>
    
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eaque hic nam reiciendis reprehenderit labore natus id! Magni, vero. Voluptates illo dolorem nesciunt ea nulla natus voluptatem expedita quidem quasi consequuntur.</p>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eaque hic nam reiciendis reprehenderit labore natus id! Magni, vero. Voluptates illo dolorem nesciunt ea nulla natus voluptatem expedita quidem quasi consequuntur.</p>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eaque hic nam reiciendis reprehenderit labore natus id! Magni, vero. Voluptates illo dolorem nesciunt ea nulla natus voluptatem expedita quidem quasi consequuntur.</p>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eaque hic nam reiciendis reprehenderit labore natus id! Magni, vero. Voluptates illo dolorem nesciunt ea nulla natus voluptatem expedita quidem quasi consequuntur.</p>
    </div>
    
    
    <div class="spinner" *ngIf="isLoading()">
        <mat-spinner diameter="30" color="primary"></mat-spinner>
    </div>
</div>
