<section fxLayout="column" fxLayout.gt-md="row" fxLayoutGap="25px" [style.backgroundColor]="bgColor">
    <div class="info content-padding" fxFlex="100%" fxFlex.gt-md="40%">
        <mat-icon *ngIf="icon" color="primary">{{ icon }}</mat-icon>
        <mat-icon class="svg" *ngIf="svgIcon" [svgIcon]="svgIcon"></mat-icon>
        <h2 class="mat-display-3 headline">{{ headline }}</h2>
        <p>{{ text }}</p>
        <!-- <button mat-raised-button *ngIf="buttonLink && buttonText" [routerLink]="buttonLink">{{ buttonText }}</button> -->
    </div>
    <div class="list" fxFlex="100%" fxFlex.gt-md="60%" cdkScrollable>
        <div 
            class="card-wrapper" 
            fxLayout="row"
            *ngIf="jobs && jobs.length && isVisible" 
            [@enterAnim]="(jobs && jobs.length && isVisible) ? 'visible' : 'hidden'">
                <app-job-card class="job-card" *ngFor="let job of jobs" [job]="job"></app-job-card>
        </div>
        <div class="card-wrapper" *ngIf="!jobs || !jobs.length || !isVisible">
            <app-job-card class="job-card-empty"></app-job-card>
        </div>
    </div>
</section>
