import { Jobdescription } from 'src/app/interfaces/jobdescription';
import { Pipe, PipeTransform } from '@angular/core';
import { MatSlideToggle } from '@angular/material/slide-toggle';

@Pipe({
  name: 'filterJobs'
})
export class FilterJobsPipe implements PipeTransform { 

  transform(items: Jobdescription[], filters: any[]): Jobdescription[] {
    let searchTerms: string[] = filters.map( (obj, index) => {
      return obj.name;
    } ); 

    let filtered: Jobdescription[] = items.filter((item: Jobdescription) => {
      return item.category.some((el) => {
        return (searchTerms.indexOf(el) !== -1);
      })
    })

    return filtered;
  }

}
