import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-image-loader',
  templateUrl: './image-loader.component.html',
  styleUrls: ['./image-loader.component.scss']
})
export class ImageLoaderComponent implements OnInit {

  @Input() src: string;
  @Input() title: string;

  isLoading:boolean;
  
  constructor() {
    this.isLoading = true;
  }

  ngOnInit(): void {
  }

  hideLoader(){
    this.isLoading = false;
  }

}
