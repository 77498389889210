import { Component, OnInit, Input } from '@angular/core';
import Typewriter from 'typewriter-effect/dist/core';

@Component({
  selector: 'app-changing-text',
  templateUrl: './changing-text.component.html',
  styleUrls: ['./changing-text.component.scss']
})
export class ChangingTextComponent implements OnInit {

  @Input() texts: string[] = ['digitális', 'ilyen', 'olyan'];
  @Input() loop: boolean = true;
  @Input() color: string = 'primary';

  word = this.texts[0];

  constructor() {
  }
  
  ngOnInit(): void {
    const tw = new Typewriter('#typewriter', {
      strings: this.texts,
      autoStart: true,
      loop: true,
      cursor: ''
    });
  }

}
 