import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-support-card',
  templateUrl: './support-card.component.html',
  styleUrls: ['./support-card.component.scss']
})
export class SupportCardComponent implements OnInit {

  @Input() icon: string;
  @Input() title: string;

  hover: boolean;
  
  constructor() {
    this.hover = false;
  }

  ngOnInit(): void {
  }

}
