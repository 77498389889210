import { Jobdetail } from './../interfaces/jobdetail';
import { Jobdescription } from './../interfaces/jobdescription';
import { Injectable } from '@angular/core';
import { Subject, of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { HttpClient, HttpClientModule } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class JobService {

  // _dummyData:Jobdescription[] = [
  //   {
  //     id: 1,
  //     title: 'Job title 1',
  //     img: 'https://material.angular.io/assets/img/examples/shiba2.jpg',
  //     category: ['online'],
  //     subcategory: 'website'
  //   },
  //   {
  //     id: 2,
  //     title: 'Job title 2',
  //     img: 'https://cdn.pixabay.com/photo/2015/10/24/05/39/creative-background-1004045_960_720.png',
  //     category: ['offline'],
  //     subcategory: 'flyer'
  //   },
  //   {
  //     id: 3,
  //     title: 'Job title 3',
  //     img: 'https://cdn.pixabay.com/photo/2020/01/09/09/06/creative-4752351_960_720.jpg',
  //     category: ['video'],
  //     subcategory: 'spot'
  //   },
  //   {
  //     id: 4,
  //     title: 'Job title 4',
  //     img: 'https://cdn.pixabay.com/photo/2015/10/24/05/39/creative-background-1004045_960_720.png',
  //     category: ['online'],
  //     subcategory: 'banner'
  //   },
  //   {
  //     id: 5,
  //     title: 'Job title 5',
  //     img: 'https://cdn.pixabay.com/photo/2020/01/09/09/06/creative-4752351_960_720.jpg',
  //     category: ['online'],
  //     subcategory: 'app'
  //   },
  //   {
  //     id: 6,
  //     title: 'Job title 6',
  //     img: 'https://cdn.pixabay.com/photo/2020/01/09/09/06/creative-4752351_960_720.jpg',
  //     category: ['online'],
  //     subcategory: 'website'
  //   },
  //   {
  //     id: 7,
  //     title: 'Job title 7',
  //     img: 'https://cdn.pixabay.com/photo/2020/01/09/09/06/creative-4752351_960_720.jpg',
  //     category: ['online'],
  //     subcategory: 'app'
  //   }
  // ];

  _dummyDetail:Jobdetail = {
    id: 1,
    description: 'this is a description of the job.',
    img: 'https://material.angular.io/assets/img/examples/shiba2.jpg',
    tags: ['job', 'tags', 'come', 'here'],
    title: 'This is a title',
    gallery: ['https://cdn.pixabay.com/photo/2015/10/24/05/39/creative-background-1004045_960_720.png', 'https://cdn.pixabay.com/photo/2020/01/09/09/06/creative-4752351_960_720.jpg']
  }

  id:Subject<number> = new Subject();
  private jobs: any;

  constructor(private http: HttpClient) {
  }
  
  getJobs(): Promise<Jobdescription[]>{
    return this.http.get<Jobdescription[]>('/assets/jobs.json').toPromise()
      .then((data) => {
        return this.sortJobs(data);
      });
  }

  filterJobsByCategory(jobs: Jobdescription[], category?: string): Jobdescription[]{
    let jobsByCat: Jobdescription[];
    if(category){
      jobsByCat = jobs.filter((item, index, self) => {
        if(item.category.indexOf(category) >= 0){
          return item;
        }
      })
    }
    else{
      jobsByCat = jobs;
    }
    return jobsByCat;
  }

  getJobdetail(id: number): Promise<any>{
    return this.http.get<Jobdetail[]>('/assets/jobdetails.json').toPromise()
      .then((data) => {
        return new Promise<any>( (resolve, reject) => {
          data.map((item, index, self) => {
            if( item.id == id ){
              resolve(item);
            } 
          });
          reject({errorCode: 404, message: 'Job not found.'});
        } )
        // .catch((err) => { console.log('promise error:', err); });
      })
      // .catch((err) => { console.log('http.get error:', err); });
  }
  
  openJob(id: number){
    this.id.next(id);
  }

  private sortJobs(jobs: Jobdescription[]): Jobdescription[] {
    return jobs.sort((a, b) => {
      return b.year-a.year
    });
  }
  
}
