import { SingleComponent } from './pages/single/single.component';
import { CategoryComponent } from './pages/category/category.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';


const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: ':category/:id',
    component: SingleComponent
  },
  // {
  //   path: ':category',
  //   // component: CategoryComponent
  //   redirectTo: '/'
  // },
  {
    path: '**',
    redirectTo: '/'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
