import { DomSanitizer } from '@angular/platform-browser';
import { transition, trigger, style, animate, query, stagger } from '@angular/animations';
import { ActivatedRoute } from '@angular/router';
import { Jobdetail } from 'src/app/interfaces/jobdetail';
import { JobService } from 'src/app/services/job.service';
import { Component, OnInit, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-single',
  templateUrl: './single.component.html',
  styleUrls: ['./single.component.scss'],
  animations: [
    trigger('enterAnim', [
      transition(':enter', [
        query('section', [style({opacity: 0, transform: 'translateY(20px)'})]),
        query('section', [
          // style({opacity: 0, transform: 'translateY(20px)'}),
          stagger('100ms', animate('300ms ease-out', style({opacity: 1, transform: 'none'})))
        ])
      ])
    ])
  ]
})
export class SingleComponent implements OnInit, AfterViewInit {

  job: Jobdetail;
  category: string;
  
  constructor(private jobService: JobService, private routes: ActivatedRoute, private domsanitiser: DomSanitizer) {
    // console.log(this.routes);
    this.routes.params.subscribe(params => {
      this.category = params['category'];
      this.jobService.getJobdetail(params['id'])
      .then((data) => {
        this.job = data;
        // console.log('getJobdetail', data);
      })
      .catch(err => { console.error('Cannot load jobdetails:', err);  })
    });
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
  }

}
