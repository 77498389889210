<mat-card class="job" *ngIf="job && job.id" [routerLink]="[job.category[0], job.id]">
    <div mat-card-image class="img-wrapper">
        <app-image-loader [src]="'/assets/refs/' + job.img" [title]="job.title"></app-image-loader>
    </div>
    <mat-card-content>
        <mat-card-subtitle>{{ job.subcategory }}</mat-card-subtitle>
        <h3>{{ job.title }}</h3>
        <small>{{ job.year }}&nbsp;&mdash;&nbsp;{{ job.client }}</small>
    </mat-card-content>
</mat-card>

<mat-card class="no-job" *ngIf="!job || !job.id">
    <mat-card-content fxLayout="column" fxLayoutAlign="center center">
        <mat-icon>feedback</mat-icon>
        <small i18n>No jobs here.</small>
    </mat-card-content>
</mat-card>