import { Jobdescription } from 'src/app/interfaces/jobdescription';
import { JobService } from 'src/app/services/job.service';
import { Component, OnInit, Input, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { trigger, transition, query, style, stagger, animate, state, animateChild } from '@angular/animations';
import { ScrollDispatcher, CdkScrollable } from '@angular/cdk/scrolling';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-category-section',
  templateUrl: './category-section.component.html',
  styleUrls: ['./category-section.component.scss'],
  animations: [
    trigger('enterAnim', [
      transition('* <=> *', [
        query(':enter', [
          style({opacity: 0, transform: 'translateY(20px)'}),
          stagger('200ms', animate('500ms ease'))
        ]),
      ])
    ])
  ]
})
export class CategorySectionComponent implements OnInit, AfterViewInit {

  @ViewChild(CdkScrollable) scrollable: CdkScrollable;
  
  @Input() jobs: Jobdescription[];
  @Input() category: string;
  @Input() icon: string;
  @Input() svgIcon: string;
  @Input() headline: string;
  @Input() text: string;
  @Input() buttonText: string;
  @Input() buttonLink: string;
  @Input() bgColor: string;
  
  // jobs: Jobdescription[];
  isVisible = false;
  private visibleOffset: number;
  
  constructor(
    private jobService: JobService,
    private scroll: ScrollDispatcher,
    iconRegistry: MatIconRegistry, 
    sanitizer: DomSanitizer
  ) {
    iconRegistry.addSvgIcon(
      'svg-cloud',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/cloud.svg'),
      );
    iconRegistry.addSvgIcon(
      'svg-video',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/video.svg'),
      );
    iconRegistry.addSvgIcon(
      'svg-offline',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/offline.svg'),
      );

    this.visibleOffset = 150;
  }
  
  ngOnInit(): void {
    // this.jobService.getJobs(this.category)
    // .then((jobs) => {
    //   this.jobs = jobs;
    // })
    // console.log('category-section', this.jobs);
  }

  ngAfterViewInit(): void {
    // console.log(this.scrollable.getElementRef().nativeElement.scrollHeight, this.scrollable.getElementRef().nativeElement.offsetHeight);
    const scrl = this.scroll.scrolled().subscribe((next: CdkScrollable) => {
      const s = this.scrollable.getElementRef().nativeElement;
      if( s.offsetTop-window.innerHeight+this.visibleOffset <= window.scrollY ){
        this.isVisible = true;
        // console.log('isVisible -> play animation');
        scrl.unsubscribe();
      }
    })
  }

}
