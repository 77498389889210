import { HttpClient } from '@angular/common/http';
import { Jobdescription } from './../../interfaces/jobdescription';
import { JobService } from 'src/app/services/job.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  
  jobs: Jobdescription[];
  supportList: Object;
  
  constructor(private jobService: JobService, private http: HttpClient) {

    jobService.getJobs()
    .then((jobs) => {
      this.jobs = jobs;
    });
    
    this.http.get('/assets/support-list.json').toPromise()
      .then((data) => {
        this.supportList = data;
    });
    
  }

  ngOnInit(): void {
  }

  filterJobsCategory(category: string): Jobdescription[]{
    if(!this.jobs || !category){
      return null;
    }
    return this.jobService.filterJobsByCategory(this.jobs, category);
  }


}
