import { Component, OnInit, NgZone } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ScrollDispatcher, CdkScrollable } from '@angular/cdk/scrolling';
import { trigger, state, style, transition, animate } from '@angular/animations';

export interface Item{
  title: string,
  path: string
}

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  animations: [
    trigger('openClose', [
      state('init', style({
        top: '0px',
        backgroundColor: 'transparent',
        color: 'white'
      })),
      state('hidden', style({
        top: '-50px',
        backgroundColor: 'transparent',
        color: 'white'
      })),
      state('visible', style({
        top: '0px',
        backgroundColor: 'white',
        color: 'black'
      })),
      transition('* => hidden', animate('200ms ease-in')),
      transition('hidden => visible', animate('200ms ease-out')),
      transition('* => init', animate('200ms')),
    ])
  ]
})


export class ToolbarComponent implements OnInit {
  
  
  menu:any[] = [];
  scrollState: string = 'init';
  private currentScroll: number = 0;
  
  constructor(
    private firestore: AngularFirestore, 
    private scroll: ScrollDispatcher,
    private zone: NgZone,
  ) {

    const scrollThreshold:number = 20;

    this.scroll.scrolled(500).subscribe(
      // next
      (scroll: CdkScrollable) => {
        // console.log('currentScroll, window.scrollY', this.currentScroll, window.scrollY);
        if( window.scrollY > scrollThreshold && this.currentScroll < window.scrollY){
          this.zone.run(() => { this.scrollState = 'hidden'; });
        }
        else if( window.scrollY <= scrollThreshold){
          this.zone.run(() => { this.scrollState = 'init'; });
        }
        else if( this.currentScroll > window.scrollY && this.scrollState == 'hidden' ){
          this.zone.run(() => { this.scrollState = 'visible'; });
        }
        this.currentScroll = window.scrollY;
      },
      // error
      (error) => { console.error('scroll error', error); },
      // complete
      () => { console.log('complete');  } 
    );

    // firestore.collection('menu').get().subscribe(collection => {
    //   collection.forEach((snapshot) => {
    //     this.menu.push(snapshot.data());
    //   })
    // });
  }

  ngOnInit(): void {
  }

}
