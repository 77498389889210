import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.scss']
})
export class HeroComponent implements OnInit {

  logos;
  
  constructor(private http: HttpClient) {
    this.http.get('/assets/frontend-logos.json').toPromise()
      .then((data) => {
        this.logos = data;
        console.log(this.logos);
    });
  }

  ngOnInit(): void {
  }

}
