import { Component, OnInit, NgModule } from '@angular/core';
import {FormControl, FormGroup, Validators, ReactiveFormsModule} from '@angular/forms';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit {

  emailFC = new FormControl('', [Validators.required, Validators.email]);
  messageMinlength = 5;
  model: any = {};
  
  constructor() { }

  ngOnInit(): void {
  }

  onSubmit(){
    console.log(`form submitted:`, this.model);
  }

  emailError(){
    return this.emailFC.hasError('required') ? 'it is wrong' : 'it is wrong too';
  }

}
